import './_subscribe-block.scss';
import { post } from 'JS/components/_request';

const subscribeBlock = function() {

	const blocks = document.querySelectorAll( 'section.subscribe-block' );
	blocks.forEach( block => {
		const emailField  	= block.querySelector( '#email' );
		const submitButton	= block.querySelector( '.js-subscribe' );
		const nonce 		= block.querySelector( '#techin_nonce' ).value;
		const errorField	= block.querySelector( '.form-element-error-msg' );
		const successDiv	= block.querySelector( '.success' );
		const groupId		= block.getAttribute( 'data-group-id' );
		const from			= block.getElementsByTagName( 'form' )[0];
		const errorClass	= 'form-element-error';
		const popup			= block.querySelector('.group-list-popup');

		const selectedChoices = [];
		let other = '';
		let isOtherSelected = false;

		emailField.addEventListener('input', () => {
			if(popup && !sessionStorage.getItem('selectedChoices')) {
				popup.classList.add('active');
			}
		});


		if(popup) {
			block.querySelector('.other-field input').addEventListener('input', () => {
				other = block.querySelector('.other-field input').value
			})
			const popupChoices  = popup.querySelectorAll('.input-checkbox input');
			if(popupChoices) {
				popupChoices.forEach( choice => {
					choice.addEventListener('change', () => {
						if(choice.checked) {
							selectedChoices.push(choice.name)

							if(choice.id == 'other') {
								block.querySelector('.other-field').classList.add('active-field');
								other = block.querySelector('.other-field input').value;
								isOtherSelected = true;
							}
						}else {
							const index = selectedChoices.indexOf(choice.name);
							if (index > -1) {
								selectedChoices.splice(index, 1);
							}
							if (choice.id === 'other') {
								block.querySelector('.other-field input').value = ""; // Clear input
								block.querySelector('.other-field').classList.remove('active-field');
								other = '';
								isOtherSelected = false;
							}
						}
					})
				})
			}
			const submitGroupChoices  = popup.querySelector('.submit-choices');
			submitGroupChoices.addEventListener('click', function(e) {
				e.preventDefault();

				if(selectedChoices && selectedChoices.length == 0) {
					block.querySelector('.subscribe-list-wrap').classList.add('error')
					return;
				}

				if(submitGroupChoices.classList.contains('disabled')) {
					submitGroupChoices.classList.remove('disabled')
				}

				if(isOtherSelected && block.querySelector('.other-field input').value == '') {
					block.querySelector('.other-field').classList.add('error');
					submitGroupChoices.classList.add('disabled')
				}else {
					popup.classList.remove('active');
					sessionStorage.setItem('selectedChoices', JSON.stringify(selectedChoices));
				}


			})
		}



		// const groupArray = selectedChoices.split(',').map(str => parseInt(str, 10));
		submitButton.addEventListener( 'click', function( e ) {
			e.preventDefault();
			if(!sessionStorage.getItem('selectedChoices')) {
				return;
			}
			const parsedChoices = JSON.parse(sessionStorage.getItem('selectedChoices'));

			if (!Array.isArray(parsedChoices) || parsedChoices.length === 0) {
				return;
			}
			try {
				post( {
					action	: 'techin/ajax/subscribe',
					nonce	: nonce,
					group	: JSON.stringify( selectedChoices),
					other   : other,
					data	: JSON.stringify( {
						value : emailField.value,
						title : emailField.title,
					} )
				} ).then( ( response ) => {
					if ( response.success ) {
						from.classList.add( 'hidden' );
						errorField.parentElement.classList.remove( errorClass );
						emailField.parentElement.classList.remove( 'has-value' );
						emailField.value = '';
						successDiv.classList.add( 'visible' );
					} else {
						successDiv.classList.remove( 'visible' );
						errorField.parentElement.classList.add( errorClass );
						errorField.innerHTML = response.data[emailField.title];
					}
				} );

			} catch ( error ) {
				console.error( error );
			}
		} );

		emailField.addEventListener( 'input', function( e ) {
			this.parentElement.classList.remove( errorClass );
		} );

	} );
};

window.addEventListener('load', () => {
	sessionStorage.removeItem('selectedChoices');
});

document.addEventListener('DOMContentLoaded', function(){
    subscribeBlock()
}, false);

window.addEventListener('acf/loaded/subscribe-block', function (e) {
    subscribeBlock()
});